body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pageRoot {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  overflow-y: hidden;
}

.react-draggable {
  cursor: move;
}

.react-resizable-hide > .react-delete-handle {
  display: none;
}

.react-resizable-handle > svg {
  bottom: 0;
  right: 0;
  position: absolute;
  width: 27px;
  height: 27px;
  background-image: none;
}

.react-delete-handle {
  top: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-resizable-handle {
  background-image: none;
  padding: 0;
  background-color: transparent;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s,
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne,
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw,
.react-grid-item > .react-resizable-handle.react-resizable-handle-se,
.react-grid-item > .react-resizable-handle.react-resizable-handle-sw
{
  transform: none;
  margin: 0;
}
.react-grid-item > .react-resizable-handle::after {
  border: none;
  content: none;
}